.ant-layout-header {
  height: 180px !important;
}

.ant-layout-content {
  padding-bottom: 25px;
}

.image-wrapper {
  /*background-color: #dfdfdf;*/
  background-color: #ffffff !important;
}

.fade-in {
  opacity: 0;
}

.fade-in.loaded {
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  opacity: 1;
}

.social-media-links {
  line-height: 20px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;
}

.social-media-links a {
  font-size: 20px;
  color: #555 !important;
}

.ant-image-preview-operations {
  display: none;
}

.float-right {
  float: right;
}

.nav-menu li {
  padding: 0 10px !important;
}

.nav-menu.ant-menu {
  font-size: 16px !important;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
  color: #666 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after{
  border-bottom: 2px solid #ACACAC !important;
}

@media screen and (min-width: 0px) {
  .logo {
    width: 100px;
  }
}

@media screen and (min-width: 600px) {
  .logo {
    width: 130px;
  }
}

@media screen and (min-width: 800px) {
  .logo {
    width: 160px;
  }
}

@media screen and (min-width: 1000px) {
  .logo {
    width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    width: 220px;
  }
}

@media screen and (min-width: 1400px) {
  .logo {
    width: 250px;
  }
}